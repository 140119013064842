import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
//import Header from "../components/header"
import Layout from "../components/layout"
const app = ({ data }) => {
  // <div style={{ color: `purple`}}>
  //   <Header headerText="Hello World!" />
  //   <p>What a world.</p>
  //   <img src="https://source.unsplash.com/random/400x200" alt="" />
  //   <Link to="/contact/">Contact</Link>
    return <Layout>
      <h1>欢迎来到{data.site.siteMetadata.title}</h1>
      <p>
        此博客还在建设当中
      </p>
      <Link to="/post-test/">test-post</Link>
    </Layout>
  // </div>
}
export default app;
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`